<template>
  <section class="my_donate">
    <div class="p-2">
      <el-select
        v-model="tabType"
        @change="changeTab()"
        placeholder="Donation Type"
        clearable
      >
        <el-option label="JCI" :value="1"></el-option>
        <el-option label="Foundation" :value="2"></el-option>
        <!-- <el-option label="Other" :value="3"></el-option> -->
      </el-select>
    </div>
    <div class="tab-content">
      <el-table
        style="width: 100%"
        :data="dataList"
        header-cell-class-name="eleTableHeader"
      >
        <el-table-column align="center" type="index" label="#" width="60">
        </el-table-column>
        <el-table-column
          align="center"
          prop="donated_type"
          label="Donation Type"
          show-overflow-tooltip
        >
        <template  slot-scope="scope">
          {{scope.row.donated_type==1?'JCI':'Foundation'}}
        </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="donate_to"
          label="Donate To"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="donation_amount"
          label="Donation Amount"
        >
          <template  slot-scope="scope">
            $ {{scope.row.donation_amount}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="donation_at"
          label="Donation At"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <iq-card class="p-3 pag_card" style="text-align: right;">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="size"
        @current-change="changePage"
        hide-on-single-page
      >
      </el-pagination>
    </iq-card>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  components: {},

  data() {
    return {
      dataList: [],
      tabType: "",

      page: 1,
      size: 10,
      total: 10
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    this.getMyDonation();
  },
  methods: {
    changeTab() {
      this.page = 1;
      this.getMyDonation();
    },

    getMyDonation() {
      this.$http
        .getMyDonation({
          donated_type: this.tabType,
          page: this.page,
          per_page: this.size
        })
        .then(res => {
          if (res.status == 200) {
            console.log(res.data.data);
            this.dataList = res.data.data;
            this.total = res.data.total;
          }
        });
    },

    changePage(value) {
      this.page = value;
      this.getMyDonation();
    }
  }
};
</script>
<style lang="scss" scoped>
.my_donate {
  background: #fff;
  .my_card {
    border-radius: 5px 5px 0 0;
    box-shadow: none;
  }

  .pag_card {
    border-radius: 0 0 5px 5px;
    box-shadow: none;
  }
}

.self_pre_input {
  > span {
    display: block;
    width: 122px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: #f8f8f8;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
  }

  ::v-deep .form-control {
    border-radius: 0;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
  }
}

::v-deep .el-input__inner {
  font-size: 14px !important;
  border-radius: 10px !important;

  > input {
    height: 45px !important;
    line-height: 45px !important;
  }
}
</style>
